import 'turbolinks';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toggle/style.css';
import 'react-datetime/css/react-datetime.css';
import 'cropperjs/dist/cropper.min.css';
import '@caterspot/react-label-sheet/style.css';
import 'flatpickr/dist/flatpickr.min.css';
import 'react-datepicker/dist/react-datepicker.css';

// Use require due to order-sensitive (ie: Bootstrap depends on jQuery and Tether to be declared beforehand)
const $ = require('jquery');
const Tether = require('tether');

global.jQuery = $;
global.Tether = Tether;

require('bootstrap');
